<template>
  <span class="social-icons">
    <span
      v-for="social in ['facebook', 'twitter', 'instagram', 'youtube']"
      :key="social"
    >
      <a
        v-if="api.options[social+'_link']"
        rel="external noreferrer"
        target="_blank"
        :href="api.options[social+'_link']"
      >
        <social-icon :type="social" />
      </a>
    </span>
  </span>
</template>

<script>
import SocialIcon from '@/assets/svg/social-icon.vue'

export default {
  components: {
    'social-icon': SocialIcon
  },
  computed: {
    api(){
      return this.$api.state;
    }
  }
}
</script>

<style lang="scss">
.social-icons {
  a {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    padding: 0 5px;
    transition: all .2s;
    @media only screen and (max-width: $m) {
      margin: 10px 5px;
    }
    &:hover {
      transform: scale(1.2);
    }
    svg {
      display: block;
      height: 20px;
      width: auto;
      path {
        fill: $white;
      }
    }
  }
}
</style>
