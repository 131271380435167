<template>
  <a
    v-if="link.external"
    rel="external noreferrer"
    target="_blank"
    :href="link.path || '/'"
  >
    {{ link.text === false ? '' : link.text || 'Click here' }}
    <arrow v-if="arrow" />
  </a>
  <router-link
    v-else
    :to="link.path || '/'"
    :title="link.text || ''"
  >
    {{ link.text === false ? '' : link.text || 'Click here' }}
    <arrow v-if="arrow" />
  </router-link>
</template>

<script>
import Arrow from '@/assets/svg/arrow.vue'

export default {
  props: [ 'link', 'arrow' ],
  components: {
    'arrow': Arrow
  },
}
</script>
