<template>
  <div class="thankyou-screen">
    <div class="row inner-width">
      <div class="col content">
        <h1 class="page-title chalk inverted">Thanks!</h1>
        <h2>Join the conversation</h2>
        <social-icons />
        <p>#BeThe12thMan</p>
      </div>
    </div>
  </div>
</template>

<script>
import SocialIcons from '@/components/helpers/social-icons.vue'

export default {
  components: {
    'social-icons': SocialIcons
  },
  computed: {
    api(){
      return this.$api.state;
    }
  },
}
</script>

<style lang="scss">
  .thankyou-screen {
    position: fixed;
    z-index: 2;
    @include fill();
    background-color: $black;
    background-image: url('./../assets/images/chalkboard.jpg');
    background-position: center;
    background-size: cover;
    margin-top: 75px;
    color: $white;
    @include center-center-content();
    text-align: center;
    .content {
      font-size: 1.3em;
      .social-icons {
        display: block;
        margin-top: 50px;
        a {
          margin: 0 10px;
          svg {
            height: 40px;
          }
        }
      }
      p {
        margin-top: 10px;
        font-weight: 600;
      }
    }
  }
</style>
