export default {
  install(Vue) {

    /*
    * dynamic router store
    **************************************************************/
    Vue.prototype.$dynamicRouter = {
      state: new Vue({data(){ return {
        posts: {},
        isHome: false
      }}}),

      // get API location of post direct from URL path
      postFromPath(path){
        return path.replace(/\//g, '_') || '_';
      },

      // load post from data
      routePost(v, data){
        // cache route and post data
        this.state.posts[data.permalink.path] = data;
        // add to router
        var site_title = v.$api.state.options.site_title;
        var post_title = data.seo_title || data.title || '';
        var frontpage = data.permalink.frontpage || false;
        var full_title = frontpage ? post_title : post_title+' | '+site_title;
        var route = {
          path: data.permalink.path,
          name: data.title,
          meta: {title: full_title},
          component: () => import('../templates/'+data.template.replace('_', '-'))
        }
        v.$router.addRoute(route);
      },

      // error page
      errorPage(v, error, status, path){
        v.$router.addRoute({
          path: path,
          name: 'Error '+Date.now(),
          meta: {title: error+' '+status},
          component: () => import('../templates/error')
        });
        v.$app.setThePost(v, {
          title: error+' '+status,
          id: 'error-'+Date.now(),
          post_type: 'page',
          template: 'error',
          error: status,
          permalink: {
            path: path
          }
        });
      },

      // change page
      changeRoute(to, from, next, v){
        // app changes
        v.$app.closeMenu();
        v.$app.clearThankYou();
        v.$app.closeMapModal();

        // check the post cache to see if we've been there before
        var cached = _.find(this.state.posts, (post) =>
        post.permalink.path === to.path);
        if(cached){
          // homepage logo action
          if(to.path === '/' && !v.$api.state.loaded){
            this.setHome();
          } else if(to.path !== '/') {
            this.resetHome();
          }

          // set post
          v.$app.setThePost(v, cached);
          v.$api.clearLoading();
          next();

        // else we load it from the api
        } else {
          v.$api.setLoading();
          v.$app.clearThePost();
          setTimeout(() => {
            var targetPath = this.postFromPath(to.path);
            var tartgetPost = '/posts/'+targetPath;
            // Preview from CMS
            if(to.query.preview){
              tartgetPost = '/preview/'+targetPath+'/';
            }
            next(v.$apiGet(tartgetPost).then(data => {
              this.routePost(v, data);

            // error 404
            }, ({error, status}) => {
              this.errorPage(v, error, status, to.path);
              v.$api.clearLoading();
            }));
          }, v.$app.viewLeaveDuration);
        }
      },

      // home logo action
      setHome(){
        this.state.isHome = true;
      },
      resetHome(){
        this.state.isHome = false;
      },
      homeClick(v){
        if(this.state.isHome){
          v.$scrollTo(0);
        } else {
          this.setHome();
        }
      },
    };

  }
};
