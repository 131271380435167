<template>
  <header
    id="header"
    :class="{'scrolled': window.scroll > 0}"
    @mouseleave="submenu.active = false"
  >
    <div class="inner">
      <div class="max-width">
        <div class="header-left">
          <router-link
            class="logo"
            to="/"
            @click.native="logoClick()"
          >
            <img src="./../assets/images/12th-man-logo.png" />
          </router-link>
          <nav
            v-if="$window.isAbove('nav')"
            class="main-menu"
          >
            <ul class="menu-links">
              <li
                v-for="(item, i) in mainMenu"
                :key="'main-item-'+i"
                :class="{'hover': submenu.active && submenu.index === i}"
                @mouseenter="menuhover(item, i, $event)"
              >
                <link-helper
                  :link="item.link"
                  :class="item.class"
                />
              </li>
            </ul>
          </nav>
        </div>
        <div class="header-right">
          <nav class="main-menu">
            <ul
              class="menu-links small-menu-links"
              v-if="$window.isAbove('nav')"
            >
              <li
                v-for="(item, i) in api.menus.secondary"
                :key="'secondary-item-'+i"
                :class="item.class"
              >
                <link-helper :link="item.link" />
              </li>
              <li class="donate-link">
                <a 
                  :href="donateLink"
                  title="Donate"
                  target="_blank"
                >
                  Donate
                </a>
                <arrow />
              </li>
            </ul>
            <ul
              class="menu-links small-menu-links"
              v-else
            >
              <li class="donate-link">
                <a 
                  :href="donateLink"
                  title="Donate"
                  target="_blank"
                >
                  Donate
                </a>
                <arrow />
              </li>
            </ul>
          </nav>
          <div
            v-if="$window.isBelow('nav')"
            class="burger-icon"
            @click="$app.toggleMenu()"
            :class="{'active': app.menuActive}"
          >
            <div class="lines">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="anim-dropdown">
      <nav
        v-if="submenu.active"
        ref="dropdown"
        class="main-menu-dropdown"
        :style="{left: submenu.offset}"
      >
        <div
          class="background"
          :style="{height: submenu.height}"
        ></div>
        <ul
          v-if="submenu.items"
          class="menu-links"
        >
          <li
            v-for="(item, i) in submenu.items"
            :key="'menu-item-'+i"
            :class="item.class"
          >
            <link-helper :link="item.link" />
            <arrow />
          </li>
        </ul>
      </nav>
    </transition>
  </header>
</template>

<script>
import Link from '@/components/helpers/link.vue'
import Arrow from '@/assets/svg/arrow.vue'

export default {
  components: {
    'link-helper': Link,
    'arrow': Arrow
  },
  data() {
    return {
      submenu: {
        index: false,
        active: false,
        items: false,
        height: false,
        offset: 0
      }
    }
  },
  watch: {
    '$route' (to, from) {
      this.submenu.index = false;
      this.submenu.active = false;
    }
  },
  computed: {
    app(){
      return this.$app.state;
    },
    api(){
      return this.$api.state;
    },
    window(){
      return this.$window.state;
    },
    mainMenu(){
      var v  = this;
      return _.map(v.api.menus.main, (item) => {
        // map interests to menu dropdown
        if(item.link.path === '/interests'){
          item.children = _.map(v.api.interests, (post) => {
            return {link: {
              external: false,
              id: post.id,
              path: post.permalink.path,
              text: post.title
            }};
          });
        }
        // map trades to menu dropdown
        if(item.link.path === '/trades'){
          item.children = _.map(v.api.trades, (post) => {
            return {link: {
              external: false,
              id: post.id,
              path: post.permalink.path,
              text: post.title
            }};
          });
        }
        return item;
      });
    },
    donateLink(){
      var v = this;
      return v.api.options.stripe_url || '';
    }
  },
  methods: {
    // hovering over top level menu item
    menuhover: function(item, i, e){
      var v = this;
      // activate submenu
      if(item.children.length){
        v.submenu = {
          index: i,
          active: true,
          items: item.children,
          height: false,
          offset: e.target.offsetLeft+'px'
        };
        v.$nextTick(function(){
          v.submenu.height = v.$refs.dropdown.offsetHeight+'px';
        });
      } else {
        v.submenu.index = false;
        v.submenu.active = false;
      }
    },
    // logo home clicked
    logoClick(){
      var v = this;
      v.$dynamicRouter.homeClick(v);
    },
  }
}
</script>

<style lang="scss">
  #header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 4;
    box-shadow: 0px 0px 10px $fade;
    @include optimise();
    .map-modal & {
      display: none;
    }
    .inner {
      background-color: $black;
      padding: 15px;
      transition: padding .4s ease;
      position: relative;
    }
    .max-width {
      max-width: $inner-width;
      margin: auto;
      @include center-content();
      justify-content: space-between;
      .header-left {
        @include center-content();
        justify-content: flex-start;
      }
      .header-right {
        @include center-content();
        justify-content: flex-end;
      }
    }
    .logo {
      width: 145px;
      height: auto;
      margin-left: 10px;
      margin-right: 30px;
      transition: all .4s ease;
      display: block;
      @media only screen and (max-width: $l) {
        margin-right: 0;
      }
      img {
        display: block;
        width: 100%;
      }
    }
    .main-menu {
      ul {
        @include clear();
        li {
          display: block;
          float: left;
        }
      }
    }
    .main-menu-dropdown {
      position: absolute;
      z-index: 2;
      top: 77px;
      left: 0;
      padding: 10px 15px 15px 15px;
      min-width: 270px;
      width: 100%;
      max-width: 400px;
      transform: translate(20px, 0px);
      transition: left .3s $easeOutQuart;
      &.anim-dropdown-enter-active, &.anim-dropdown-leave-active {
        transition: left .3s $easeOutQuart, opacity .1s, transform .2s ease-out;
      }
      &.anim-dropdown-enter, &.anim-dropdown-leave-to {
        transform: translate(0px, 0px);
        opacity: 0;
      }
      .background {
        position: absolute;
        @include fill();
        background-color: $white;
        transition: height .3s $easeOutQuart;
        box-shadow: 0px 10px 20px -10px $fade;
      }
      .menu-links {
        position: relative;
        li {
          a {
            margin: 0;
            font-size: 17px;
            color: $black;
            font-weight: 600;
            transform: translate(0, 0);
          }
          &:before {
            height: 2px;
            top: auto;
            bottom: 5px;
            background-color: $black;
            transform: translate(0, 0);
            transition: all .1s;
            height: 2px;
            opacity: 0;
            width: auto;
            left: 10px;
            right: 10px;
          }
          &:hover {
            a {
              transform: translate(5px, 0);
            }
            &:before {
              opacity: 1;
            }
          }
        }
      }
    }
    .burger-icon {
      position: relative;
      width: 40px;
      height: 40px;
      margin: 0 20px 0 40px;
      cursor: pointer;
      @media only screen and (max-width: $xl) {
        margin: 0 10px 0 25px;
      }
      @media only screen and (max-width: $s) {
        margin: 0 0 0 15px;
      }
      .lines {
        position: absolute;
        width: 36px;
        height: 25px;
        top: calc(50% - 13px);
        right: calc(50% - 18px);
        transition: right .5s ease;
      }
      span {
        display: block;
        height: 3px;
        border-radius: 4px;
        background-color: $white;
        position: absolute;
        width: 35px;
        transition: transform .2s $easeOutQuart, opacity .1s;
        &:nth-child(1){
          top: 0;
          left: 0;
          transform-origin: 100% 0%;
        }
        &:nth-child(2){
          top: calc(50% - 1.5px);
          left: 0;
        }
        &:nth-child(3){
          bottom: 0;
          left: 0;
          transform-origin: 0% 0%;
        }
      }
      &.active {
        span {
          transition: transform .3s $easeOutQuart, opacity .3s .3s;
          &:nth-child(1){
            transform: translate(-7px, 0px) rotate(-45deg);
          }
          &:nth-child(2){
            transform: rotate(45deg);
          }
          &:nth-child(3){
            transform: translate(4px, 2px) rotate(-45deg);
          }
        }
      }
    }
    &.scrolled {
      .inner {
        padding: 10px;
      }
      .logo {
        width: 95px;
      }
      .main-menu-dropdown {
        top: 58px;
      }
    }
    @media only screen and (max-width: $xl) {
      .logo {
        width: 110px;
      }
    }
    @media only screen and (max-width: $m) {
      .inner {
        padding: 10px;
      }
      .logo {
        width: 95px;
      }
      &.scrolled {
        .logo {
          width: 95px;
        }
      }
    }
  }
</style>
