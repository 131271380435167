<template>
  <div class="app-alert">
    <div class="row inner-width">
      <div class="col content">
        <p v-text="alert.text" />
        <link-helper
          class="button small"
          :arrow="true"
          :link="alert.link"
        />
      </div>
    </div>
    <a
      href="#"
      class="close"
      @click.prevent="api.options.alert_text = false"
      v-text="'+'"
    />
  </div>
</template>

<script>
import Link from '@/components/helpers/link.vue'

export default {
  components: {
    'link-helper': Link
  },
  computed: {
    api(){
      return this.$api.state;
    },
    alert(){
      return {
        text: _.get(this.api, 'options.alert_text'),
        link: _.get(this.api, 'options.alert_link')
      };
    },
  }
}
</script>

<style lang="scss">
.app-alert {
  position: absolute;
  padding-top: 113px;
  top: 0;
  left: 0;
  right: 0;
  background-color: $white;
  padding: 125px 15px 10px 15px;
  z-index: 3;
  transition: all .3s ease;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  max-width: 100vw;
  overflow: hidden;
  @include optimise();
  @media only screen and (max-width: $xl) {
    padding-top: 105px;
  }
  @media only screen and (max-width: $l) {
    padding: 15px 20px;
    top: auto;
    bottom: 0;
    position: fixed;
  }
  .row {
    padding: 0;
    position: relative;
  }
  .content {
    p {
      float: left;
      padding: 0 200px 0 0;
      font-size: 0.9em;
      @media only screen and (max-width: $l) {
        padding: 0 40px 10px 0;
      }
    }
    .button {
      position: absolute;
      top: -4px;
      right: 0;
      font-size: 0.9em;
      @media only screen and (max-width: 1345px) {
        right: 50px;
      }
      @media only screen and (max-width: $l) {
        position: relative;
        top: 0;
        right: 0;
      }
    }
  }
  .close {
    text-decoration: none;
    font-family: 'almaq-rough', sans-serif;
    transform: rotate(45deg);
    display: block;
    position: absolute;
    text-align: center;
    bottom: 5px;
    right: 10px;
    font-size: 40px;
    line-height: 35px;
    width: 40px;
    height: 40px;
    color: $black;
    @media only screen and (max-width: $l) {
      bottom: auto;
      top: 10px;
    }
  }
}
</style>
