<template>
  <div id="burger-menu">
    <div class="centered">
      <nav class="main-menu">
        <ul>
          <li
            v-for="(item, i) in api.menus.main"
            :key="'menu-item-'+i"
          >
            <link-helper
              :link="item.link"
              :class="item.class"
            />
          </li>
        </ul>
      </nav>
      <nav class="secondary-menu">
        <ul>
          <li
            v-for="(item, i) in api.menus.secondary"
            :key="'menu-item-'+i"
          >
            <link-helper
              :link="item.link"
              :class="item.class"
            />
          </li>
        </ul>
      </nav>
    </div>
    <social-icons />
  </div>
</template>

<script>
import Link from '@/components/helpers/link.vue'
import SocialIcons from '@/components/helpers/social-icons.vue'

export default {
  components: {
    'link-helper': Link,
    'social-icons': SocialIcons
  },
  computed: {
    api(){
      return this.$api.state;
    }
  },
}
</script>

<style lang="scss">
  #burger-menu {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 80px 10vw 100px 10vw;
    background-color: $black;
    @include center-content();
    @media only screen and (max-width: $xs) {
      padding: 80px 8vw 30px 8vw;
    }
    nav {
      width: 100%;
      ul {
        li {
          display: block;
          a {
            display: inline-block;
            margin: 0 10px;
            padding: 10px;
            text-decoration: none;
            color: $white;
            transition: all .2s;
            font-size: 30px;
            line-height: 40px;
            font-weight: 600;
            position: relative;
            @media only screen and (max-width: $ms) {
              font-size: 28px;
              line-height: 30px;
            }
            @media only screen and (max-width: $s) {
              font-size: 24px;
              line-height: 20px;
            }
            @media only screen and (max-width: $xs) {
              font-size: 22px;
              line-height: 13px;
            }
            &:hover {
              opacity: 0.5;
            }
            &.router-link-active {
              &:after {
                content: '';
                position: absolute;
                display: block;
                height: 3px;
                left: 6px;
                right: 6px;
                bottom: 3px;
                background-color: $white;
              }
            }
          }
        }
      }
      &.secondary-menu {
        margin-top: 20px;
        ul {
          li {
            a {
              text-transform: uppercase;
              font-size: 24px;
              line-height: 30px;
              font-weight: 400;
              @media only screen and (max-width: $ms) {
                font-size: 22px;
                line-height: 28px;
              }
              @media only screen and (max-width: $s) {
                font-size: 18px;
                line-height: 15px;
              }
              @media only screen and (max-width: $xs) {
                font-size: 15px;
                line-height: 10px;
              }
            }
          }
        }
      }
    }
    .social-icons {
      position: fixed;
      left: 10vw;
      bottom: 50px;
      @media only screen and (max-width: $s) {
        bottom: 30px;
      }
    }
  }
</style>
