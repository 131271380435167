<template>
  <div
    id="app"
    :class="app.classes.concat([{
      'menu-active': app.menuActive,
      'map-modal': app.mapModal && $window.isBelow('l')
    }])"
  >
    <!-- header -->
    <transition name="fade">
      <app-header v-if="api.loaded" />
    </transition>

    <!-- alert -->
    <transition :name="$window.isBelow('l') ? 'alert-mobile' : 'alert'">
      <app-alert v-if="api.loaded && alert && ($window.isBelow('l') || window.scroll <= 0)" />
    </transition>

    <!-- view -->
    <div id="app-content">
      <transition
        name="anim-view"
        mode="out-in"
        :duration="200"
        @after-leave="beforePageEnter"
        @before-leave="pageChanging = true"
        @after-enter="pageChanging = false"
      >
        <router-view
          class="view"
          :class="{'is-loading': api.isLoading}"
          v-if="app.thePost && loaded"
          :key="app.thePost.id"
        />
      </transition>

      <!-- thank you -->
      <transition name="fade">
        <thankyou-screen v-if="app.thankyouScreen" />
      </transition>

      <!-- loading -->
      <transition
        name="anim-view-cover"
        mode="out-in"
      >
        <div
          class="view-cover"
          v-if="pageChanging"
        />
      </transition>
      <transition name="anim-view-spinner">
        <svg
          id="view-spinner"
          v-if="!api.loaded || api.isLoading"
          viewBox="0 0 50 50"
        >
          <circle cx="25" cy="25" r="22"></circle>
        </svg>
      </transition>
    </div>

    <!-- footer -->
    <app-footer v-if="api.loaded && !app.thankyouScreen" />

    <!-- burger menu -->
    <transition name="anim-burger" :duration="1000">
      <burger-menu
        v-if="api.loaded && app.menuActive && $window.isBelow('nav')"
      />
    </transition>
  </div>
</template>

<script>
import Header from '@/components/app-header.vue'
import Footer from '@/components/app-footer.vue'
import Alert from '@/components/app-alert.vue'
import BurgerMenu from '@/components/burger-menu.vue'
import ThankyouScreen from '@/components/thankyou-screen.vue'

export default {
  name: 'App',
  components: {
    'app-header': Header,
    'app-footer': Footer,
    'app-alert': Alert,
    'burger-menu': BurgerMenu,
    'thankyou-screen': ThankyouScreen
  },
  data(){
    return {
      pageChanging: false
    }
  },
  computed: {
    api(){
      return this.$api.state;
    },
    app(){
      return this.$app.state;
    },
    window(){
      return this.$window.state;
    },
    alert(){
      return _.get(this.api, 'options.alert_text');
    },
    loaded(){
      var v = this;
      return v.api.loaded && v.api.interests.length && v.api.trades.length && v.api.locations.length;
    },
  },
  methods: {
    beforePageEnter(){
      this.$resetScroll();
    }
  }
}
</script>

<style lang="scss">
  // reset
  @import './assets/scss/_reset.scss';
  // global stylesheets
  @import './assets/scss/_transitions.scss';
  @import './assets/scss/_keyframes.scss';
  @import './assets/scss/_waypoints.scss';
  @import './assets/scss/_global.scss';
  @import './assets/scss/_forms.scss';
  @import './assets/scss/_content.scss';
</style>
