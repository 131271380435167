// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/chalkboard.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".thankyou-screen{position:fixed;z-index:2;top:0;left:0;right:0;bottom:0;background-color:#000;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-size:cover;margin-top:75px;color:#fff;display:flex;align-items:center;justify-content:center;height:100%;text-align:center}.thankyou-screen .content{font-size:1.3em}.thankyou-screen .content .social-icons{display:block;margin-top:50px}.thankyou-screen .content .social-icons a{margin:0 10px}.thankyou-screen .content .social-icons a svg{height:40px}.thankyou-screen .content p{margin-top:10px;font-weight:600}", ""]);
// Exports
module.exports = exports;
