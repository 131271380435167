export default {
  install(Vue) {

    /*
    * api store
    **************************************************************/
    Vue.prototype.$api = {
      state: new Vue({data(){ return {
        menus: {},
        options: {},
        categories: {},
        trades: [],
        interests: [],
        locations: [],
        loaded: false,
        isLoading: true,
        error: '',
        status: 0
      }}}),
      url: '/api',

      // initialise
      init(v){
        var $api = this;
        // trades, interests and locations
        Promise.all([
          v.$apiGet('/query/trade/fields/all/'),
          v.$apiGet('/query/interest/fields/all/'),
          v.$apiGet('/query/location/fields/all/')
        ]).then(async([trade, interest, location]) => {
          $api.state.trades = trade.posts;
          $api.state.interests = interest.posts;
          $api.state.locations = location.posts;
          _.each(trade.posts.concat(interest.posts), function(post){
            v.$set($api.state.categories, post.id, post);
          });
        });
        // init api or ssr
        if(v.$route.query.preview || !window.$ssr){
          $api.initAPI(v);
        } else if(window.$ssr){
          $api.initSSR(v);
        } else {
          v.$api.setError(v, 'API init failed', 404);
          $api.state.loaded = true;
          $api.clearLoading();
        }
      },

      // initial data
      initAPI(v){
        var $api = this;
        // use API for previews and development
        Promise.all([
          v.$apiGet('/menus'),
          v.$apiGet('/options')
        ]).then(async([menus, options]) => {
          $api.state.options = options;
          $api.state.menus = menus;
          var path = v.$dynamicRouter.postFromPath(v.$route.path);
          Promise.all([
            v.$apiGet(v.$route.query.preview ? '/preview/'+path+'/' : '/posts/'+path),
          ]).then(async([post]) => {
            v.$dynamicRouter.routePost(v, post);
            $api.state.loaded = true;
            $api.clearLoading();
          }).catch(error => {
            v.$dynamicRouter.errorPage(v, error.error, error.status, v.$route.path);
            $api.state.loaded = true;
            $api.clearLoading();
          });
        });
      },
      initSSR(v){
        var $api = this;
        // prerender when available
        var $ssr = JSON.parse(window.$ssr);
        v.$log($ssr, 'Prerendered data: ');
        $api.state.options = $ssr.options;
        $api.state.menus = $ssr.menus;
        if($ssr.error){
          v.$api.setError(v, $ssr.error.error, $ssr.error.status);
          v.$dynamicRouter.errorPage(v, $ssr.error.error, $ssr.error.status, v.$route.path);
        } else {
          v.$dynamicRouter.routePost(v, $ssr.post);
          // homepage logo action
          var path = $ssr.post.permalink.path;
          if(path === '/'){
            v.$dynamicRouter.setHome();
          }
          // set post
          v.$app.setThePost(v, $ssr.post);
        }
        $api.state.loaded = true;
        $api.clearLoading();
      },

      // errors
      setError(v, error, status){
        this.state.error = error;
        this.state.status = status;
        v.$log(error, status);
      },
      clearError(){
        this.state.error = '';
        this.state.status = 0;
      },

      // is loading
      setLoading(){
        this.state.isLoading = true;
      },
      clearLoading(){
        this.state.isLoading = false;
      },

      // get headers
      getHeaders() {
        let headers = {
          'Content-Type': 'application/json',
          'Pragma': 'no-cache',
          'Cache-Control': 'no-cache'
        };
        let user = localStorage.getItem('user');
        if(user) {
          headers['Authorization'] = 'Bearer ' + JSON.parse(user).jwt;
          headers['hextail-cms'] = 'true';
        }
        return headers;
      }
    };

    /*
    * $_POST API
    **************************************************************/
    Vue.prototype.$apiPost = function(action, data) {
      var v = this;
      v.$log('action: '+action, 'POST');
      return fetch(v.$api.url, {
        method: 'POST',
        headers: this.$api.getHeaders(),
        body: JSON.stringify({
          action: action,
          data: !data ? {} : data
        })
      })
      .then(response => v.$apiProcess(response));
    };

    /*
    * $_GET API
    **************************************************************/
    Vue.prototype.$apiGet = function(params) {
      var v = this;
      var fresh = 'v='+Date.now();
      fresh = params.includes('?') ? '&'+fresh : '?'+fresh;
      v.$log('from: '+v.$api.url+params+fresh, 'GET');
      return fetch(v.$api.url+params+fresh, {
        method: 'GET',
        headers: v.$api.getHeaders()
      })
      .then(response => v.$apiProcess(response));
    };

    /*
    * process response from API
    **************************************************************/
    Vue.prototype.$apiProcess = function(response) {
      return response.text().then(text => {
        var v = this;
        let api;
        // check for valid JSON
        try {
          let o = JSON.parse(text);
          if (o && typeof o === "object") {
            api = o;
          }
        }
        // catch PHP errors and dumps
        catch (e) {
          const status = 500;
          v.$api.setError(v, text+' | '+response.url, status);
          v.$api.clearLoading();
          return Promise.reject({text, status});
        }
        // error handling
        if(!response.ok) {
          const error = (api.data && api.data.error) || response.statusText;
          const status = response.status;
          v.$api.setError(v, error, status);
          v.$api.clearLoading();
          return Promise.reject({error, status});
        }
        // success message
        if(api.data.success) {
          v.$log(api.data.success+': '+response.url, 'Success');
        }
        // warning message
        if(api.data.warning) {
          v.$log(api.data.warning+': '+response.url, 'Warning');
        }
        // successful data
        const data = api.data;
        v.$log(data, 'data: '+response.url);
        return data;
      });
    };

  }
};
