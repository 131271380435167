<template name="social-icon">
  <!-- instagram -->
  <svg v-if="type === 'instagram'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.132 20.132" preserveAspectRatio="xMidYMin slice">
    <path data-name="Path 11653" d="M10.066 1.812c2.684 0 3 .008 4.068.059C16.86 2 18.135 3.288 18.261 6c.05 1.065.059 1.384.059 4.068s-.008 3-.059 4.068c-.126 2.709-1.392 4-4.127 4.127-1.065.05-1.376.059-4.068.059s-3-.008-4.068-.059c-2.735-.126-4-1.426-4.127-4.127-.05-1.065-.059-1.376-.059-4.068s.008-3 .059-4.068C2 3.288 3.271 2 6 1.871c1.063-.042 1.382-.059 4.066-.059zm0-1.812C7.331 0 6.987.008 5.914.059 2.265.226.226 2.256.059 5.914.008 6.987 0 7.331 0 10.066s.008 3.078.059 4.152c.168 3.657 2.2 5.687 5.855 5.855 1.074.05 1.418.059 4.152.059s3.078-.008 4.152-.059c3.649-.168 5.687-2.2 5.855-5.855.05-1.074.059-1.418.059-4.152s-.008-3.078-.059-4.152c-.168-3.649-2.2-5.687-5.855-5.855C13.144.008 12.8 0 10.066 0zm0 4.9a5.167 5.167 0 1 0 5.167 5.167A5.169 5.169 0 0 0 10.066 4.9zm0 8.522a3.355 3.355 0 1 1 3.355-3.355 3.354 3.354 0 0 1-3.355 3.354zm5.377-9.94a1.208 1.208 0 1 0 1.208 1.208 1.2 1.2 0 0 0-1.208-1.209z" />
  </svg>
  <!-- youtube -->
  <svg v-else-if="type === 'youtube'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 18" preserveAspectRatio="xMidYMin slice">
    <path data-name="Path 14353" d="M23.859 3.984Q24 6.516 24 9t-.141 5.016a3.847 3.847 0 0 1-1.008 2.7 3.209 3.209 0 0 1-2.461 1.1Q16.781 18 12 18t-8.391-.187a3.209 3.209 0 0 1-2.461-1.1 3.847 3.847 0 0 1-1.008-2.7Q0 11.484 0 9q0-1.641.188-5.016a3.9 3.9 0 0 1 .984-2.7A3.139 3.139 0 0 1 3.61.184Q7.031 0 11.578 0h.844q4.547 0 7.969.188a3.209 3.209 0 0 1 2.461 1.1 3.847 3.847 0 0 1 1.007 2.696zM9.7 13.594L16.5 9 9.7 4.359z" />
  </svg>
  <!-- twitter -->
  <svg v-else-if="type === 'twitter'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.125 18" preserveAspectRatio="xMidYMin slice">
    <path data-name="Path 14354" d="M22.125 2.156a8.856 8.856 0 0 1-2.25 2.344v.563a13.166 13.166 0 0 1-3.539 8.93A11.922 11.922 0 0 1 6.938 18 12.522 12.522 0 0 1 0 15.938a9.9 9.9 0 0 0 1.078.094A8.817 8.817 0 0 0 6.7 14.063a4.563 4.563 0 0 1-2.625-.914 4.294 4.294 0 0 1-1.591-2.227 5.64 5.64 0 0 0 .844.094 5.269 5.269 0 0 0 1.219-.187 4.582 4.582 0 0 1-2.6-1.57A4.32 4.32 0 0 1 .891 6.375v-.047a4.274 4.274 0 0 0 2.063.563 4.257 4.257 0 0 1-2.016-3.8 4.51 4.51 0 0 1 .609-2.25 12.288 12.288 0 0 0 9.328 4.734 4.1 4.1 0 0 1-.094-1.031 4.4 4.4 0 0 1 1.313-3.234A4.4 4.4 0 0 1 15.328 0a4.331 4.331 0 0 1 3.281 1.453A8.449 8.449 0 0 0 21.515.328 4.158 4.158 0 0 1 19.5 2.859a7.742 7.742 0 0 0 2.625-.703z" />
  </svg>
  <!-- facebook -->
  <svg v-else-if="type === 'facebook'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 18" preserveAspectRatio="xMidYMin slice">
    <path data-name="Path 14355" d="M6 6h3l-.375 3H6v9H2.109V9H0V6h2.109V3.984a4.1 4.1 0 0 1 .984-2.977A4.458 4.458 0 0 1 6.375 0H9v3H7.406a1.62 1.62 0 0 0-1.148.281A1.373 1.373 0 0 0 6 4.219z" />
  </svg>
  <!-- none set -->
  <svg v-else></svg>
</template>

<script>
export default {
  props: [ 'type' ],
}
</script>
