export default {
  install(Vue) {

    /*
    * app store
    **************************************************************/
    Vue.prototype.$app = {
      state: new Vue({data(){ return {
        menuActive: false,
        thePost: false,
        classes: [],
        thankyouScreen: false,
        mapModal: false
      }}}),
      version: process.env.VUE_APP_VERSION,
      env: process.env.NODE_ENV,

      // initialise - very first function - only ran once
      init(v){
        v.$api.init(v);
        v.$window.init(v);
        v.$media.init(v);
      },

      // burger menu helpers
      toggleMenu(){
        this.state.menuActive = !this.state.menuActive;
      },
      openMenu(){
        this.state.menuActive = true;
      },
      closeMenu(){
        this.state.menuActive = false;
      },

      // clear the current post
      clearThePost(){
        this.state.thePost = false;
      },

      // get page title
      pageTitle(v, post){
        var title = '';
        var siteTitle = v.$api.state.options.site_title;
        if(post.permalink.frontpage){
          title = post.seo_title ? post.seo_title : siteTitle;
        } else {
          title = post.seo_title ? post.seo_title : post.title;
          title = title+' | '+siteTitle;
        }
        return title;
      },

      // set the current post
      setThePost(v, post){
        this.state.thePost = post;

        // document title
        var title = this.pageTitle(v, post);
        document.title = title;

        // document desc
        var desc = post.seo_desc || v.$api.state.options.site_desc || '';
        var description = document.head.querySelector('meta[name="description"]');
        description.setAttribute('content', desc);

        // document canonical
        var url = _.trimEnd(v.$api.state.options.site_url || window.location.origin, '/');
        var canonical = document.head.querySelector('link[rel="canonical"]');
        canonical.setAttribute('href', url+post.permalink.path);

        // robots
        var indexing = post.seo_no_index || v.$debug ? 'noindex, nofollow' : 'index, follow';
        var robots = document.head.querySelector('meta[name="robots"]');
        robots.setAttribute('content', indexing);

        // post theming
        var classes = [
          'post-type-'+post.post_type,
          'template-'+post.template.replace('_', '-')
        ];
        if(post.permalink.frontpage){
          classes.push('frontpage');
        }
        this.state.classes = classes;
      },

      // set thank you screen
      setThankYou(){
        this.state.thankyouScreen = true;
      },
      clearThankYou(){
        this.state.thankyouScreen = false;
      },

      // open map modal
      openMapModal(){
        this.state.mapModal = true;
      },
      closeMapModal(){
        this.state.mapModal = false;
      },

    };

  }
};
