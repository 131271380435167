export default {
  install(Vue){

    /*
    * window store
    **************************************************************/
    Vue.prototype.$window = {
      state: new Vue({data(){return{
        width: 0,
        height: 0,
        size: false,
        scroll: 0,
        retina: false,
        browser: '',
      }}}),
      breakpoints: {
        'xs': 330,
        's': 480,
        'ms': 624,
        'm': 840,
        'l': 1024,
        'nav': 1100,
        'xl': 1366
      },

      // initialise
      init(v){
        var $w = this;
        // size
        window.addEventListener('resize', _.throttle(function(){
          $w.updateSize();
          v.$waypoints.update(v);
        }, 24));
        $w.updateSize();
        // scroll
        document.addEventListener('scroll', _.throttle(function(){
          $w.updateScroll();
          v.$waypoints.update(v);
        }, 24), {
          passive: true
        });
        $w.updateScroll();
        // retina
        $w.state.retina = $w.isRetinaDisplay();
        // browser
        $w.state.browser = $w.isBrowser();
      },

      // update screen properties
      updateSize(){
        var $w = this;
        // set screen size breakpoint initial or full (f)
        $w.state.size = _.findKey($w.breakpoints, (width) =>
        window.matchMedia('(max-width: '+width+'px)').matches) || 'f';
        // set width and height
        $w.state.width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        $w.state.height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
      },

      // screen size helpers
      isAbove(size){
        return this.state.width > this.breakpoints[size];
      },
      isBelow(size){
        return this.state.width <= this.breakpoints[size];
      },

      // update scroll properties
      updateScroll(){
        this.state.scroll = window.scrollY || document.documentElement.scrollTop;
      },

      // check for retina screen
      isRetinaDisplay(){
        if(window.matchMedia){
          var mq = window.matchMedia("only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)");
          return (mq && mq.matches || (window.devicePixelRatio > 1));
        }
      },

      // basic browser checks
      isBrowser(){
        if(/^((?!chrome|android).)*safari/i.test(navigator.userAgent)){
          return 'safari-browser';
        } else if (/Edge/.test(navigator.userAgent)) {
          return 'edge-browser';
        } else if (/firefox/i.test(navigator.userAgent)) {
          return 'firefox-browser';
        } else if (/Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)) {
          return 'chrome-browser';
        }
        return '';
      }
    };

    /*
    * reset scroll
    **************************************************************/
    Vue.prototype.$resetScroll = function(){
      window.scrollTo(0, 0);
    };

    /*
    * smooth scroll to object or positon
    * offset is 0 to 1 (0.5 being center of the screen)
    **************************************************************/
    Vue.prototype.$scrollTo = function(pos, offset){
      if(typeof pos !== 'number' && !pos){
        return;
      }
      var top = typeof pos === 'number' ? pos : pos.offsetTop;
      if(offset){
        top -= this.$window.state.height * offset;
        if(typeof pos !== 'number'){
          top += pos.offsetHeight / 2;
        }
      }
      window.scrollTo({
        'behavior': 'smooth',
        'left': 0,
        'top': top
      });
    };

  }
};
