<template>
  <footer id="footer">
    <div class="inner">
      <div class="max-width">
        <div class="inner-width">
          <div class="footer-left">
            <nav class="footer-main" v-if="$window.isAbove('s')">
              <ul class="menu-links">
                <li
                  v-for="(item, i) in api.menus.main"
                  :key="'menu-item-'+i"
                  :class="item.class"
                >
                  <link-helper :link="item.link" />
                </li>
              </ul>
            </nav>
            <nav class="footer-secondary" v-if="$window.isAbove('ms')">
              <ul class="menu-links">
                <li
                  v-for="(item, i) in api.menus.secondary"
                  :key="'menu-item-'+i"
                  :class="item.class"
                >
                  <link-helper :link="item.link" />
                </li>
              </ul>
            </nav>
            <div class="social">
              <p>
                Join the conversation
              </p>
              <p>
                #BeThe12thMan
                <social-icons />
              </p>
            </div>
          </div>
          <div class="footer-right">
            <a href="https://the-outsiders.org.uk/" target="_blank">
              <outsiders-logo
                class="outsiders-logo"
                alt="A project by the Outsiders"
              />
            </a>
          </div>
        </div>
      </div>
      <div class="max-width">
        <div class="inner-width">
          <nav class="footer-menu">
            <ul>
              <li
                v-for="(item, i) in api.menus.footer"
                :key="'menu-item-'+i"
                :class="item.class"
              >
                <link-helper :link="item.link" />
              </li>
            </ul>
          </nav>
          <div class="credit">
            <p>
              <a
                href="https://outlab.digital"
                rel="external noreferrer"
                target="_blank"
              >
                <span>made by</span>
                <img src="./../assets/images/outlab-logo.svg" />
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import OutsidersLogo from '@/assets/svg/outsiders-logo.vue'
import SocialIcons from '@/components/helpers/social-icons.vue'
import Link from '@/components/helpers/link.vue'

export default {
  components: {
    'link-helper': Link,
    'outsiders-logo': OutsidersLogo,
    'social-icons': SocialIcons,
  },
  computed: {
    api(){
      return this.$api.state;
    }
  },
}
</script>

<style lang="scss">
  #footer {
    .inner {
      background-color: $black;
      position: relative;
      z-index: 1;
    }
    .max-width {
      .inner-width {
        max-width: $inner-width;
        margin: auto;
        padding: 0 30px;
        @include center-content();
        justify-content: space-between;
      }
      &:first-child {
        @media only screen and (max-width: $l) {
          .inner-width {
            flex-direction: column;
          }
        }
      }
      &:last-child {
        background: $dark-grey;
        .inner-width {
          padding: 10px 30px;
        }
        @media only screen and (max-width: $l) {
          .inner-width {
            padding: 10px;
          }
        }
      }
      .footer-left {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 80px 0;
        margin-right: 100px;
        @media only screen and (max-width: $xl) {
          padding: 40px 0;
          margin-right: 50px;
        }
        nav {
          .donate-link {
            display: none;
          }
        }
        p {
          font-family: 'almaq-refined', sans-serif;
          font-size: 20px;
          color: $white;
          padding: 10px;
        }
        @media only screen and (max-width: $m) {
          .social-icons {
            display: block;
          }
        }
      }
      .footer-right {
        .outsiders-logo {
          display: block;
          width: 280px;
          height: auto;
          transition: width .3s;
          path {
            fill: $white;
          }
          .logo-b {
            fill: none;
          }
          @media only screen and (max-width: $xl) {
            width: 200px;
            padding-bottom: 50px;
          }
        }
      }
    }
    .footer-menu {
      @media only screen and (max-width: $l) {
        text-align: center;
      }
      ul {
        li {
          display: block;
          float: left;
          a {
            color: $white;
            font-size: 14px;
            margin: 0 5px;
            padding: 10px 5px;
          }
          @media only screen and (max-width: $l) {
            float: none;
            display: inline-block;
          }
        }
      }
    }
    .credit {
      a {
        margin: 0 5px;
        padding: 10px;
        font-size: 12px;
        text-decoration: none;
        color: $white;
        transition: all .2s;
        &:hover {
          opacity: 0.5;
        }
        @include center-content();
        span {
          display: block;
          margin-right: 10px;
          line-height: 1em;
          margin-top: 4px;
        }
        img {
          display: block;
          width: 80px;
          height: auto;
        }
      }
    }
  }
</style>
